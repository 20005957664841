<template>
  <!-- 关键词 -->
  <div class="storage"
    v-loading.fullscreen.lock="loading"
    element-loading-text="请求数据中"
    element-loading-background="rgba(255, 255, 255, 0.5)"
   >
    <div class="append">
      <div class="seach">
        <el-select 
          filterable
          placeholder="请选择店铺" 
          v-model="pageInfoList.profileId"
        >
          <el-option-group 
            :key="group.id"
            :label="group.name"
             v-for="group in shopLists"
            >
             <el-option 
              v-for="item in group.marketPlaceTree"
              :key="item.profileId"
              :label="group.name+'-'+item.name"
              :value="item.profileId"
              @click.native="checkGroup(group.id, item.id, item.profileId,item.currency)"
             >{{ item.name }}
             </el-option>
          </el-option-group>
        </el-select>
        <TimeQuantum
          style="margin-right:10px" 
          @selectFinish='topTime'
          section="近7天"
          @sectionFinish='sectionSure'
        ></TimeQuantum>
        <el-select 
          filterable 
          clearable 
          @clear="removeEvent"
          placeholder="请输入后/直接选择组合" 
          v-model="pageInfoList.adCombination"
          @change="find"
        >
           <el-option
             v-for="item in portfoliosList"
             :key="item.id"
             :label="item.value"
             :value="item.id"
             @click.native="change_event(item.id)"
            >{{item.value}}
           </el-option>
        </el-select>
        <el-select 
          filterable 
          clearable 
          @clear="clearEmpty"
          placeholder="请输入后/直接选择广告活动" 
          v-model="pageInfoList.adActivity"
          @change="find"
        >
          <el-option
            v-for="item in adVertisCampaignList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
            @click.native="handle_event(item.id)"
           >
           <p>
             <span>({{item.type}})</span>-
             <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
             <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
             <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
             <span>{{item.value}}</span>
           </p>
          </el-option>
        </el-select>
        <el-select 
          clearable 
          filterable 
          placeholder="请输入后/直接选择组" 
          v-model="pageInfoList.adGroup"
          @change="find"
        >
          <el-option
           v-for="item in adCollectionList"
           :key="item.id"
           :label="item.value"
           :value="item.id"
           >
            <p>
              <span>({{item.type}})</span>-
              <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
              <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
              <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
              <span>{{item.value}}</span>
            </p>
          </el-option>
        </el-select>
        <el-select 
          clearable 
          filterable 
          placeholder="所有匹配类型" 
          v-model="pageInfoList.adTypes"
          @change="find"
        >
          <el-option
            v-for="item in matchTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-select 
          clearable 
          filterable 
          placeholder="所有关键词状态" 
          v-model="pageInfoList.keyWordState"
          @change="find"
        >
         <el-option
           v-for="item in keywordStateList"
           :key="item.id"
           :label="item.value"
           :value="item.id"
         ></el-option>
        </el-select>
        <el-select 
          filterable 
          clearable 
          placeholder="所有广告活动状态" 
          v-model="pageInfoList.adActiveState"
          @change="find"
        >
          <el-option
           v-for="item in campaignsStateList"
           :key="item.id"
           :label="item.value"
           :value="item.id"
          ></el-option>
        </el-select>
        <el-select 
          filterable 
          clearable 
          placeholder="所有关注状态" 
          v-model="pageInfoList.adAttentionState"
          @change="find"
        >
          <el-option
            v-for="item  in favoriteTypeList"
            :key="item.id"
            :label="item.value"
            :value="item.id"
          ></el-option>
        </el-select>
        <el-input v-model="pageInfoList.keyWords" placeholder="请输入关键词" @keyup.enter.native = "find"></el-input>
        <el-button type="primary" icon="el-icon-search" @click="find">立即查询</el-button>
        <el-button type="primary" icon="el-icon-setting" @click="handleManage">管理关键词</el-button>
        <el-dropdown style="margin-left: 20px" @command="handleCommand">
          <el-button icon="el-icon-warning" type="primary">批量操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item 
             v-for="ele in operateTypeList"
             :key="ele.id"
             :command="ele.id"
            >{{ ele.values }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 列表区域 -->
        <el-table
          border
          :data="tableData"
          tooltip-effect="dark"
          @sort-change="sortTable"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          header-cell-style='background:#FAFAFA; text-align:center; font-size:14px'
         >  
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column label="关键词" prop="keywordText" align="center" width="150" fixed :show-overflow-tooltip="true">
            <template slot-scope="scope">
              <p style="text-align: left; display: flex; align-items: center">
                <span @click="focusChange(scope.row.id, scope.row.focusOn)" :class="scope.row.focusOn== 0?'el-icon-star-off' : 'el-icon-star-on'" style="font-size:17px; margin-right:10px"></span>
                <span>{{scope.row.keywordText}}</span>
              </p>
            </template>
          </el-table-column>
          <el-table-column label="启停/分析" prop="" align="center" fixed>
            <template slot-scope="scoped">
              <div style="display: flex; justify-content:space-around; align-items:center; padding:0 20px">
                <el-tooltip effect="dark" content="已归档" placement="top">
                  <span v-if="scoped.row.state == 'archived'" class="el-icon-document"></span>
                </el-tooltip>
                <el-switch
                  v-if="scoped.row.state != 'archived'"
                  v-model="scoped.row.state"
                  active-color="#13ce66"
                  inactive-color="#C1C1C1"
                  active-value="enabled"
                  inactive-value="paused"
                  @change="changeStatus(scoped.row.keywordId, scoped.row.state, scoped.row.$index)"
                ></el-switch>
                <el-tooltip effect="dark" content="查看历史趋势" placement="top">
                  <el-link class="iconfont icon-jingyingfenxi" style="font-size:22px" @click="getChart(scoped.row)"></el-link>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="广告组" prop="adGroupName" align="center" fixed :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="广告活动" prop="campaignName" align="center" :show-overflow-tooltip="true"></el-table-column>
          <el-table-column label="匹配类型" prop="matchType" align="center"></el-table-column>
          <el-table-column label="活动状态" prop="campaignState" align="center">
             <template slot-scope="scope">
               <span v-if="scope.row.campaignState=='已启用'" style="color: #3CB371">{{ scope.row.campaignState }}</span>
               <span v-if="scope.row.campaignState=='已暂停'" style="color: #B22222">{{ scope.row.campaignState }}</span>
               <span v-if="scope.row.campaignState=='已归档'" style="color: #808080">{{ scope.row.campaignState }}</span>
             </template>
          </el-table-column>
          <!-- <el-table-column prop="" align="center">
            <template slot="header">
              <span>定时策略</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:420px">
                  <p>广告对象定时策略绑定情况。</p>
                  <p>悬停策略类型名称上，可以查看策略的名称、状态、生效状态和应用策略状态。</p>
                  <p>点击策略类型名称，可以查看策略的详情、启停应用状态和修改分时计算基准值。</p>
                  <p>策略类型名称如果是<span style="color:#BF8350">灰色字体，表示策略当前处于不被执行状态</span>（策略未生效、策略被暂停、对象暂停应用了策略）。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column> -->
          <el-table-column align="center">
            <template slot="header">
              <span>建议竞价</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:420px">
                    <p>建议竞价是为您量身定制的，有助于您的关键词赢得展示次数。</p>
                    <p>建议竞价范围基于与您的广告类似且在过去 7 天内赢得了展示次数的广告的竞价。 <a style="color:#13CE66" target="top" href="https://advertising.amazon.com/help/ref=ams_head_help?entityId=ENTITY3VAZBWP88M62S#GYQY2B3R58ZHSHJJ">了解更多信息</a></p><br>
                    <p>自动投放未归档的广告组、未归档的关键词和商品定位可以获取建议竞价。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-link 
               type="primary"
               v-if="scope.row.suggested == undefinded && scope.row.state != 'archived'"
               @click="onObtainBtn(scope.$index, scope.row.keywordId)"
              >获取</el-link>
              <div v-else-if="scope.row.suggested!=undefinded" style="font-size:14px; text-align:center">
                <p>{{currency}}{{ scope.row.suggested }}</p>
                <p>{{currency}}{{ scope.row.rangeStart }}--{{currency}}{{ scope.row.rangeEnd }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" prop="bid" sortable='custom'>
            <template slot="header">
              <span>竞价</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>当关键词触发您的广告时，您需要为点击支付的金额。 <a style="color:#13CE66" href="https://advertising.amazon.com/help?entityId=ENTITY3VAZBWP88M62S#GTX8JYBTJX5EUCZW" target="top">了解更多信息</a></p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-tooltip effect="dark" content="点击可调整竞价" placement="top">
                <el-link type="primary" @click="onClickBid(scope.row, scope.$index)">{{currency}}{{scope.row.bid}}</el-link>
              </el-tooltip>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="" align="center">
            <template slot="header">
              <span>周搜索排名</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;width:300px">
                  该关键词系统最近收录的周Search Frequency Rank(ARA数据的关键词搜索频率排名)。<br/>
                  <span style="color:#DAA520">特别说明:</span>&nbsp;&nbsp;&nbsp;值为: "--", 表示该关键词最近收录时, 未进入搜索排名。
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column> -->
          <el-table-column prop="impressions" align="center" sortable='custom'>
            <template slot="header">
              <span>曝光量</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content">
                  <p>广告投放给买家的展示总数。</p><br>
                  <p>没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="clicks" align="center" sortable='custom'>
            <template slot="header">
              <span>点击量</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>点击量：广告被点击的总次数。</p>
                    <p>点击率：每一次曝光被点击的概率。</p><br>
                    <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                    <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                    <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                    <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="clickRate" align="center" sortable='custom'>
            <template slot="header">
              <span>点击率</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>点击量：广告被点击的总次数。</p>
                    <p>点击率：每一次曝光被点击的概率。</p><br>
                    <p>计算规则：广告点击率 = 点击量 / 曝光量 * 100%；</p><br>
                    <p>亚马逊广告点击率一般在0.6%-2.5%左右。</p>
                    <p>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</p>
                    <p>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</p>
                </div>
               <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="cost" align="center" sortable='custom'>
            <template slot="header">
              <span>花费</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>广告被点击，所产生的总费用，相当于您的广告总成本 。</p><br>
                    <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p><br>
                    <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                    <p>3、计算规则：广告花费 = CPC竞价 * 被点击次数；</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{currency}}{{scope.row.cost}}
            </template>
          </el-table-column>
          <el-table-column prop="clickAveCost" align="center" sortable='custom'>
            <template slot="header">
              <span>CPC均价</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>CPC点击的平均价格。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{currency}}{{scope.row.clickAveCost}}
            </template>
          </el-table-column>
          <el-table-column prop="attributedConversions14d" align="center" sortable='custom'>
            <template slot="header">
              <span>订单数</span>
               <el-tooltip effect="dark" placement="top">
                  <div slot="content" style="line-height:20px;min-width:150px;max-width:350px">
                    <p>通过广告带来的订单量。</p><br>
                    <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                    <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                  </div>
                 <span class="el-icon-question"></span>
               </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="cvr" align="center" sortable='custom'>
            <template slot="header">
              <span>CVR</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>每一次点击带来订单的概率。</p><br>
                    <p>计算规则：CVR = 订单数 / 点击量 * 100%；</p>
                    <p>例如：CVR为10%，每100次点击，能带来10笔广告订单。</p><br>
                    <p>亚马逊广告，CVR一般为5-10%左右，</p>
                    <p>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动，如果CVR低，建议您优化listing，根据商品的受众人群，提炼卖点、设计精美的场景图、善用Q&A，Review等。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="cpa" align="center" sortable='custom'>
            <template slot="header">
              <span>CPA</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>每一笔广告订单平均所需花费用</p><br>
                    <p>计算规则：CPA = 花费 / 订单数；</p><br>
                    <p>商品的新品期，CPA相对较高，</p>
                    <p>商品的稳定期，CPA逐渐降低。</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="attributedSales14d" align="center" sortable='custom'>
            <template slot="header">
              <span>销售额</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>通过广告带来的销售额。</p><br>
                    <p>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致，因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</p>
                    <p>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口，不同接口的请求时间或者报告生成时间可能不一致，因此可能导致两个维度下统计结果存在略微不一致；</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              {{currency}}{{scope.row.attributedSales14d}}
            </template>
          </el-table-column>
          <el-table-column prop="acos" align="center" sortable='custom'>
            <template slot="header">
              <span>ACoS</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>广告销售成本率，广告花费占广告销售额的比例。</p><br>
                    <p>计算规则：ACoS = 广告花费 / 广告销售额 * 100%；</p>
                    <p>例如，ACoS为90%，100美元的销售额，需要支付90%（90元）广告费。</p>
                    <p>ACoS是衡量广告效果的重要指标，一般来说越低越好，</p>
                    <p>25%是业内普遍的衡量标准，</p>
                    <p>但并不能用ACoS指标单一来衡量广告的效果。</p><br>
                    <p>在不同的细分市场、商品周期、用户周期，品牌定位，都影响着ACoS的设置目标，</p>
                    <p>例如，客户复购率高、忠诚度高、终身价值高，或是有品牌光环和生态的商品，</p>
                    <p>通过广告一次获客，客户可带来长期的购买回报，</p>
                    <p>因此这类商品ACoS高，反而有益于创造更多利润。</p><br>
                    <p>拓展阅读<a href="https://www.baidu.com/s?ie=UTF-8&wd=ACoS%E8%B6%8A%E4%BD%8E%E8%B6%8A%E5%A5%BD%E5%90%97%EF%BC%9F&tn=62095104_41_oem_dg" style="color:#13CE66">ACoS越低越好吗？</a></p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column prop="roas" align="center" sortable='custom'>
            <template slot="header">
              <span>ROAS</span>
              <el-tooltip effect="dark" placement="top">
                <div slot="content" style="line-height:20px;min-width:150px;max-width:300px">
                    <p>广告投入回报比，广告销售额与成本的倍数。</p><br>
                    <p>计算规则：RoAS = 销售额 / 花费；</p>
                    <p>例如，RoAS为2，说明花10美元做广告，带来了20美元的销售额。</p><br>
                    <p>RoAS是衡量广告效果投入成本的效率指标，一般情况下，越大越好，亚马逊上的平均RoAS约为3，消费类电子产品的RoAS约为9，玩具和游戏的RoAS约为4.5，</p>
                </div>
                <span class="el-icon-question"></span>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :total="total"
          :page-size="20"
          :current-page="pageInfoList.current">
        </el-pagination>
      </div>
      <!-- 管理关键词 -->
      <el-dialog
        width="1200px"
        title="管理关键词"
        :visible.sync="keyWordVisible"
      >
         <el-form :label-position="labelPosition" :model="formLabelAlign" label-width="80px">
           <el-form-item label="广告活动">
             <el-select 
               clearable
               filterable
               style="width: 620px"
               placeholder="请选择广告活动"
               v-model="formLabelAlign.adActivity" 
               @change="changeValue"
              >
               <el-option
                v-for="item in adCampaignList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
                @click.native="handle_select(item)"
               >
                <template>
                  <span>({{item.type}})</span>-
                  <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                  <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                  <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                  <span>{{item.value}}</span>
                </template>
               </el-option>
             </el-select>
             <span v-if="type == 1" @click="clickBtn(type)" class="activityInfor">活动信息 (查看)</span>
             <span v-if="type == 2" @click="clickBtn(type)" class="activityInfor">活动信息 (隐藏)</span>
           </el-form-item>
           <!-- 广告活动详情 -->
           <div class="showClass" v-show="show_div">
             <div class="look">
                <div class="fn">
                  <div class="lable">广告组合</div>
                  <div class="value">{{detailsObject.type}}/{{detailsObject.value}}</div>
                </div>
                <div class="fn">
                  <div class="lable">活动起止日期</div>
                  <div class="value">{{ detailsObject.date }}</div>
                </div>
                <div class="fn">
                  <div class="lable">每日预算({{currency}})</div>
                  <div class="value">{{ detailsObject.bid }}</div>
                </div>
                <div class="fn">
                  <div class="lable">定向策略</div>
                  <div class="value">{{ detailsObject.targetingType }}</div>
                </div>
                <div class="fn">
                  <div class="lable">活动竞价策略</div>
                  <div class="value">{{ detailsObject.target }}</div>
                </div>
                <div class="fn">
                  <div class="lable">商品页面</div>
                  <div class="value">{{ detailsObject.page }}</div>
                </div>
                <div class="fn">
                 <div class="lable">搜索结果顶部</div>
                 <div class="value">{{ detailsObject.top }}</div>
                </div>
             </div>
           </div>
           <el-form-item label="广告组">
             <el-select 
              filterable
              style="width: 620px"
              placeholder="请选择广告组"
              v-model="formLabelAlign.adGrounp" 
              @change="handleChange"
             >
               <el-option
                v-for="(item,index) in arrList"
                :key="item.id"
                :label="item.value"
                :value="item.id"
                :disabled="arrList[index].type == '商'? true : false"
              >
                <template>
                  <span>({{item.type}})</span>-
                  <span v-if="item.state == 'enabled'" style="color:#1AFA29" class="iconfont icon-duihao"></span>
                  <span v-if="item.state == 'paused'" style="font-size:14px;color:red" class="iconfont icon-zantingtingzhi"></span>
                  <span v-if="item.state == 'archived'" style="font-size:14px;color:#DCDFE6" class="iconfont icon-guidang"></span>
                  <span>{{item.value}}</span>
                </template>
               </el-option>
             </el-select>
             <span v-if="data == 1" @click="handleClick(data)" class="activityInfor">广告组信息 (查看)</span>
             <span v-if="data == 2" @click="handleClick(data)" class="activityInfor">广告组信息 (隐藏)</span>
           </el-form-item>
           <!-- 广告组详情 -->
           <div class="class_div" v-show="show_adGroup">
             <div class="look">
               <div class="fn">
                 <div class="lable">定向策略</div>
                 <div class="value">{{ AdGroupInfoObj.targetType }}</div>
               </div> 
               <div class="fn">
                <div class="lable">默认竞价</div>
                <div class="value">{{ AdGroupInfoObj.default }}</div>
               </div>
             </div>
           </div>
           <div v-show="show_mainContent">
             <!-- 筛选条件区域 -->
             <div class="nav_top">
               <el-row type="flex" justify="flex-start">
                 <div class="el_row_top">
                   <h4 style="margin-right: 5px">商品</h4>
                   <el-tooltip placement="top">
                     <div slot="content">
                       添加您想要在此广告活动中推广的商品。<br/>
                       <b>1</b>: 由于系统同步非实时, 因此商品状态, 库存数据, 售价, 可能存在一定延迟。<br/>
                       <b>2</b>: 分组标签为您在s2系统中设置的。<br/>
                       <b>3</b>: 预计可售天天数是由您在s2系统中配置的近30日销售权重和销售情况计算而得。
                     </div>
                     <i class="el-icon-question" style="cursor: pointer; margin-right: 10px"></i>
                   </el-tooltip>
                 </div>
                 <el-select 
                   placeholder="商品状态" 
                   v-model="formData.status" 
                   style="margin-right: 20px"
                 >
                   <el-option
                    v-for="item in goodsStatusList"
                    :key="item.id"
                    :label="item.value"
                    :value="item.id"
                   > 
                   </el-option>
                 </el-select>
                 <el-input 
                   clearable
                   v-model="keyword" 
                   placeholder="请输入ASIN"
                   style="width:360px; margin-right: 20px" 
                 >
                   <el-select
                     clearable
                     slot="prepend"
                     @clear="clearAsin"
                     v-model="select_Asin" 
                     placeholder="选择输入类型" 
                     style="width: 130px"
                   >
                     <el-option
                      v-for="item in subASINList"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                     </el-option>
                   </el-select>
                 </el-input>
                 <el-button icon="el-icon-search" :loading='buttonLoading' type="primary" @click="asyncSearch">立即查询</el-button>
               </el-row>
             </div>
             <!-- 中间区域 -->
             <div class="el_nav_middle_div">
                <!-- 左侧卡片区域 -->
                <el-card class="box-card">
                  <div>
                    <el-button type="text" class="handleAll" @click="GoodsAll('add')">添加全部</el-button>
                    <el-tabs v-model="CurSelecteName" @tab-click="handle_Click_first">
                      <el-tab-pane label="搜索" name="first"></el-tab-pane>
                      <el-tab-pane label="输入列表" name="second">
                        <span slot="label">
                          输入列表
                          <el-radio-group v-model="radioState" v-show="radioState_flag">
                            <el-radio label="asin">子ASIN</el-radio>
                            <el-radio label="parent_asin">父ASIN</el-radio>
                          </el-radio-group>
                        </span>
                      </el-tab-pane>
                    </el-tabs>
                    
                    <div v-if="CurSelecteName == 'first'" v-loading='dialogLoading'>
                      <div style="height:450px;overflow:auto">
                        <div class="item" v-for="item in searchTdata" :key="item.id">
                            <div class="pic">
                                <img :src="item.imageUrl" alt="" width="100%">
                            </div>
                            <div class="SKU">
                                <p>
                                    <span class="state"><img :src="item.marketPlaceImageUrl" alt="" width="100%"></span><span>子：{{item.asin}}</span>
                                </p>
                                <p>
                                    <span>SKU：{{item.commoditySku}}</span>
                                </p>
                            </div>
                            <div class="realizable">
                                <p>可售库存：{{item.sellableQuantity}}</p>
                                <p>可售天数：{{item.canSellDay}}</p>
                            </div>
                            <div class="price">
                                <p>售价：{{currency}}{{item.sellPrice}}</p>
                            </div>
                            <div style="font-weight:blod; font-size:23px; color:#409EFF; width:30px">
                              <span @click="toAdded(item)" v-if="item.operation==0" class="el-icon-circle-plus-outline"></span>
                             </div>
                        </div>
                      </div>
                      <el-pagination
                        @size-change="handleSizeChange2"
                        @current-change="handleCurrentChange2"
                        :current-page="formData.current"
                        :page-sizes="[10, 30, 50]"
                        :page-size="formData.pageSize"
                        layout="total, sizes, prev, pager, next"
                        :total="total_count"
                        style="margin-top: 10px; text-align: cenetr"
                        >
                      </el-pagination>
                    </div>
                    <div v-if="CurSelecteName == 'second'">
                      <el-input 
                        v-model="textField"
                        type="textarea" rows="18" 
                        placeholder="手动输入ASIN, 多个时换行输入 如 
 B077ZKF9ZN 
 B07X1QPRYQ 
 B07V2CLJLV" 
                        style="margin-top: 20px"></el-input>
                      <el-button 
                        type="primary" 
                        @click="handle_Add"
                        icon="el-icon-circle-plus" 
                        style="float:right; margin-top:20px" 
                      >添加</el-button>
                    </div>
                  </div>
                </el-card>
                <!-- 右侧卡片 -->
                <el-card class="box-card">
                  <el-button type="text" class="removeAll" @click="GoodsAll('del')">删除全部</el-button>
                  <el-tabs v-model="activeName">
                    <el-tab-pane :label="'已存在'+'('+rightData.length+')'" name="first"></el-tab-pane>
                  </el-tabs>
                  <div style="height: 450px; overflow: auto;" v-loading='dialogLoading'>
                    <template v-for="(item,index) in rightData">
                      <div class="item" :key="item.id">
                        <div class="pic">
                          <img :src="item.imageUrl" alt="" height="100%" width="100%">
                        </div>
                        <div class="SKU">
                          <p>
                            <span class="state"><img :src="item.marketPlaceImageUrl" alt="" width="100%"></span><span>子：{{item.asin}}</span>
                          </p>
                          <p>
                            <span>SKU：{{item.commoditySku}}</span>
                          </p>
                        </div>
                        <div class="realizable">
                          <p>可售库存：{{item.sellableQuantity}}</p>
                          <p>可售天数：{{item.canSellDay}}</p>
                        </div>
                        <div class="price">
                          <p>售价：{{currency}}{{item.sellPrice}}</p>
                        </div>
                        <div style="font-weight: blod; font-size:23px; color:#409EFF; width: 30px">
                          <el-link v-if="item.exist == 1"></el-link>
                          <span @click="toGoods(item, index)" class="el-icon-delete" v-else></span>
                        </div >
                      </div>
                    </template>
                  </div>
                </el-card>
             </div>

             <!-- 底部区域 -->
             <div class="el_nav_bottom_div">
               <!-- 左侧区域 -->
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <div>
                      <el-button class="handleAll" type="text" @click="handleAllAdd">添加全部</el-button>
                    </div>
                    <el-tabs v-model="CurSelecteOption" @tab-click="handleClick">
                      <!-- 前边 -->
                      <div class="el_filter">
                        <span>筛选条件</span>
                        <el-tooltip placement="top">
                            <div slot="content" style="width:250px;line-height:19px">
                                <p>匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。</p><br>
                                <p>广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。</p>
                                <p>词组匹配： 包含确切的词组或词序相同的关键词。</p>
                                <p>精准匹配： 与相应关键词或关键词的词序完全匹配。</p>
                            </div>
                            <i class="iconfont icon-wenhao"></i>
                        </el-tooltip>
                        <!-- 单选组区域 -->
                        <el-checkbox style="margin-left:5px" v-model="screen" label="exact">精准匹配</el-checkbox>
                        <el-checkbox v-model="screen" label="phrase">短语匹配</el-checkbox>
                        <el-checkbox v-model="screen" label="broad">宽泛匹配</el-checkbox>
                      </div>
                      <el-tab-pane name="third">
                        <span slot="label">建议
                          <el-tooltip placement="top">
                            <div slot="content" style="width:250px;line-height:19px">
                              <p>建议关键词以您广告中的商品为基础。</p>
                              <p>您可以添加所需的关键词，并在添加后编辑关键词和竞价。<a style="color:#13CE66" target="top" href="https://advertising.amazon.com/help?entityId=ENTITY3VAZBWP88M62S#GK3MNACNTXG659J9">了解更多信息</a></p>
                            </div>
                            <i class="iconfont icon-wenhao"></i>
                          </el-tooltip>
                        </span>
                        <div style="height: 400px; overflow:auto">
                          <el-table
                           height="390"
                           :data="left_Tdata"
                           style="width: 100%; margin-top:10px;"
                           header-cell-style="background:#FAFAFA;font-size:14px"
                          >
                            <el-table-column header-align="left" label="关键词">
                              <template slot-scope="scope">
                                <p style="font-size: 14px">{{scope.row}}</p>
                              </template>
                            </el-table-column>
                            <el-table-column align="center" label="添加" width="70">
                              <template slot-scope="scope">
                                <span @click="addKeywords(scope.row)" style="font-size:23px; color:#409EFF;" class="el-icon-circle-plus-outline"></span>
                              </template>
                            </el-table-column>
                          </el-table>
                        </div>
                      </el-tab-pane>
                      <el-tab-pane label="输入列表" name="fourth">
                        <el-input 
                          :rows="8" 
                          type="textarea" 
                          v-model="middle_textarea"
                          placeholder="" 
                          style="margin-top: 20px"
                         ></el-input>
                        <el-divider content-position="left"></el-divider>
                        <el-button 
                          type="primary" 
                          @click="async_Add" 
                          style="float: right;" 
                          icon="el-icon-circle-plus"
                        >添加关键词</el-button>
                      </el-tab-pane>
                    </el-tabs>
                  </div>
                </el-card>
                <!-- 右侧区域 -->
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>已存在{{addedNokeywords}}个, 并添加{{right_Data.length-addedNokeywords}}个</span>
                    <el-button style="float: right; padding: 3px 0; background: none; border: none" type="text" @click="handleAllDelete1">全部删除</el-button>
                    <div style="height: 400px;">
                      <el-table
                       height='400'
                       :data="right_Data"
                       cell-style="font-size:14px"
                       style="width: 100%; margin-top:15px"
                       header-cell-style="background:#FAFAFA; font-size:14px"
                      >
                        <el-table-column label="关键词" prop="keywordText" header-align="left"></el-table-column>
                        <el-table-column label="匹配类型"  align="center" width="100">
                          <template slot-scope="scope">
                            <span v-if="scope.row.matchType == 'exact'">精准匹配</span>
                            <span v-if="scope.row.matchType == 'phrase'">短语匹配</span>
                            <span v-if="scope.row.matchType == 'broad'">宽泛匹配</span>
                          </template>
                        </el-table-column>
                        <el-table-column label="竞价" header-align="center" width="150">
                          <template slot-scope="scope">
                            <el-input v-if="scope.row.exist == true" disabled style="width:120px" v-model="scope.row.bid" placeholder="竞价">
                              <el-button slot="append">{{currency}}</el-button>
                            </el-input>
                            <el-input v-else style="width:120px" v-model="scope.row.bid" placeholder="竞价">
                              <el-button slot="append">{{currency}}</el-button>
                            </el-input>
                          </template>
                        </el-table-column>
                        <el-table-column label="删除" header-align="center" width="80">
                          <template slot-scope="scope">
                            <el-button size="mini" disabled v-if="scope.row.exist == true">已存在</el-button>
                            <el-button v-else @click="delKeywords(scope.$index, right_Data)" style="font-size:23px;" type="text" class="el-icon-delete"></el-button>
                          </template>
                        </el-table-column>
                      </el-table>
                    </div>
                  </div>
                </el-card>
             </div>
             <div class="newkeywords">
               <el-button type="primary" :loading='buttonLoading2' @click="asyncAddKeyword">确定新增关键词</el-button>
             </div>
           </div>

          <!-- 底部折叠区域 -->
          <el-collapse v-model="activeNames" accordion>
            <el-collapse-item title="否定关键词" name="1">
              <div class="el_collapse_div">
                <!-- 左侧区域 -->
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <span>
                      匹配类型
                      <el-tooltip placement="top">
                          <div slot="content" style="line-height:19px;width:250px">
                              <p>匹配类型让您能够微调哪些顾客搜索内容会触发您的广告。</p><br>
                              <p>广泛匹配： 包含以任何顺序排列的所有关键词，包括复数、变体和相关关键词。</p>
                              <p>词组匹配： 包含确切的词组或词序相同的关键词。</p>
                              <p>精准匹配： 与相应关键词或关键词的词序完全匹配。</p>
                          </div>
                          <span class="iconfont icon-wenhao" style="margin-right:9px"></span>
                      </el-tooltip>
                    </span>
                    <el-radio-group v-model="radioAccurate">
                      <el-radio
                        v-for="(data, index) in negativeKeywordType"
                        :key="index"
                        :label="data.value"
                        :value="data.id"
                      ></el-radio>
                    </el-radio-group>

                     <el-input 
                      :rows="8" 
                      type="textarea" 
                      v-model="textarea"
                      placeholder="请输入您的列表, 并用新行将每行隔开" 
                      style="margin-top: 20px"
                     ></el-input>
                    <el-divider content-position="left"></el-divider>
                    <el-button
                      type="primary" 
                      icon="el-icon-circle-plus" 
                      style="float: right;"
                      @click="addKeyWord"
                     >添加关键词</el-button>
                  </div>
                </el-card>
                <!-- 右侧区域 -->
                <el-card class="box-card">
                  <div slot="header" class="clearfix">
                    <!-- 需要归档的 -->
                    <span style="margin-right:10px;color:#409EFF;cursor: pointer;" v-show="addedNoGoods!=0" @click="updateNeKeyword">归档</span>
                    <span>已存在{{addedNoGoods}}个, 并添加{{tdata.length-addedNoGoods}}</span>
                    <el-button style="float: right; padding: 3px 0; background: none; border: none" type="text" @click="handleAllDelete2">全部删除</el-button>
                    <el-table
                      :data="tdata"
                      height="230px"
                      header-cell-style='background:#FAFAFA; font-size:14px'
                      style="margin-top: 20px"
                      @selection-change="selectNoKeywords"
                      v-loading="pictLoading"
                      element-loading-text="数据正在加载中"
                      element-loading-spinner="el-icon-loading"
                     >
                      <el-table-column
                        width="55"
                        type="selection"
                        :selectable="archivedKeywordSelect"
                      ></el-table-column>
                      <el-table-column 
                        label="关键词" 
                        prop="keywordText" 
                        align="left"
                      ></el-table-column>
                      <el-table-column label="匹配类型" prop="" align="center">
                        <template slot-scope="scope">
                          <span v-if="scope.row.matchType == 'negativeExact'">否定精准</span>
                          <span v-if="scope.row.matchType == 'negativePhrase'">否定词组</span>
                        </template>
                      </el-table-column>
                      <el-table-column label="操作" align="right">
                        <template slot-scope="scope">
                          <el-link disabled v-if="scope.row.operation == 1">已存在</el-link>
                          <el-link 
                           v-else
                           type="danger" 
                           :underline="false" 
                           icon="el-icon-delete" 
                           @click="handleDelete(scope.$index)"
                         >删除</el-link>
                        </template>
                      </el-table-column>
                    </el-table>
                  </div>
                  <div class="el-async_keyword">
                    <el-button type="primary" :loading='buttonLoading3' @click="asyncAddNoKeyword">确定新增否定关键词</el-button>
                  </div>
                </el-card>
              </div>
            </el-collapse-item>
          </el-collapse>
         </el-form>
      </el-dialog>

      <!-- 批量调整竞价 -->
      <el-dialog
        center
        width="600px"
        title="批量调整竞价"
        :visible.sync="batchAdjustBidVisible"
      >
        <el-form :inline="true" ref="ruleForm" :model="formInline">
           <el-form-item>
            <el-select 
             style="width: 260px"
             v-model="formInline.selectValue"
             @change="changeOperateType"
            >
               <el-option 
                v-for="item in adjustBidOperateTypeList"
                :key="item.id"
                :label="item.values"
                :value="item.id"
               ></el-option>
             </el-select>
           </el-form-item>
           <el-form-item>
             <el-input 
              type="number" 
              style="width: 260px" 
              placeholder="请输入金额"
              v-model="formInline.numberValue" 
             >
             <i slot="suffix" class="el-input__icon" v-show="percentShow">
               <img src="../../../src/assets/common/percent.png" style="width: 26px; height: 26px; margin-top: 4px">
             </i>
             <i slot="prefix" class="el-input__icon" v-show="rmbShow">
               <img src="../../../src/assets/common/rmb.png" style="width: 18px; height: 18px; margin-top: 8px; margin-left: 2px">
             </i>
             </el-input>
           </el-form-item>
           <div>
             <el-row style="color: red; font-size: 16px">说明:</el-row>
             <el-row style="margin: 20px 0; font-size: 16px">1: 将竞价统一调整到: 输入的金额就是目标竞价;</el-row>
             <el-row style="margin: 20px 0; font-size: 16px">2: 将竞价按百分比提高/降低: 目标竞价 = (1 ± 输入值) / 100 * 当前的竞价;</el-row>
             <el-row style="margin: 20px 0; font-size: 16px">3: 将竞价按固定金额增加/减少: 目标竞价 = 输入的金额 ± 当前的竞价;</el-row>
             <el-row style="margin: 20px 0; font-size: 16px">4: 调整后的目标竞价, 小于2时, 系统会将目标竞价调整为2, 大于100000时, <br/>系统调整为100000;</el-row>
             <el-row style="font-size: 16px">5: 调整后的目标竞价, 按照四舍五入最多保留两位小数;</el-row>
           </div>
           <el-row class="botton_group_bottom">
             <el-button @click="batchAdjustBidVisible = false">取消</el-button>
             <el-button type="primary" :loading="btnLoading" @click="asyncSubmit('ruleForm')">确定</el-button>
           </el-row>
        </el-form>
      </el-dialog>

      <!-- 批量应用建议竞价 -->
      <el-dialog
        center
        width="600px"
        title="批量应用建议竞价"
        :visible.sync="batchApplicationVisible"
      >
        <span style="font-size: 16px">您确定要把选中的&nbsp{{multipleSelection.length}}&nbsp个自动投放的关键词的竞价, 调整为亚马逊建议竞价吗？ </span>
        <el-row class="botton_group_bottom">
          <el-button @click="batchApplicationVisible = false">取消</el-button>
          <el-button :loading="btnLoading2" type="primary" @click="asyncSubmit()">确定</el-button>
        </el-row>
      </el-dialog>

      <!-- 批量暂停广告组 -->
      <el-dialog
        center
        width="600px"
        title="批量暂停广告组"
        :visible.sync="batchSuspendVisible"
      >
        <span style="font-size: 16px">您确定要把选中的&nbsp{{multipleSelection.length}}&nbsp个启动状态的关键词, 调整为<span style="color: #FF0000">暂停</span>状态吗?</span>
        <el-row class="botton_group_bottom">
          <el-button @click="batchSuspendVisible = false">取消</el-button>
          <el-button type="primary" @click="asyncSubmit()">确定</el-button>
        </el-row>
      </el-dialog>

      <!-- 批量启动广告组 -->
      <el-dialog  
        center
        width="600px"
        title="批量启动广告组"
        :visible.sync="batchStartVisible"
      >
        <span style="font-size: 16px">您确定要把选中的&nbsp{{multipleSelection.length}}&nbsp个暂停状态的关键词, 调整为<span style="color: #008000">启动</span>状态吗?</span>
        <el-row class="botton_group_bottom">
          <el-button @click="batchStartVisible = false">取消</el-button>
          <el-button type="primary" @click="asyncSubmit()">确定</el-button>
        </el-row>
      </el-dialog>

      <!-- 批量归档广告组 -->
      <el-dialog
        center
        width="600px"
        title="批量归档广告组"
        :visible.sync="batchArchiveVisible"
      >
       <span style="font-size: 16px">您确定要把选中的&nbsp{{multipleSelection.length}}&nbsp个关键词, <span style="color: #0000FF">归档</span>吗?</span>
       <el-row class="botton_group_bottom">
         <el-button @click="batchArchiveVisible = false">取消</el-button>
         <el-button type="primary" @click="asyncSubmit()">确定</el-button>
       </el-row>
      </el-dialog>

      <!-- 底部抽屉区域 -->
      <el-drawer
       title="关键词表现"
       :modal="false"
       size="60%"
       :visible.sync="drawer"
       :direction="direction"
       :custom-class="DrawerClass"
       :before-close="handleClose">
        <TendencyChart 
        :list.sync='ListOfData' 
        :info='`关键词：${keywordTextShow}  广告组:${adGroupNameShow}`'
        :legendList='legendList'
        :currency="currency"
        @analysisClick='analysisClick'></TendencyChart>
      </el-drawer>
    </div>
  </div>
</template>

<script>
import {
  PageInit,  // 初始化
  getListPage,   // 查询分页初始化
  getAddInit,    // 新增初始化
  getAsinByAsins,
  getSuggestedKeywordsByAsin,  //  (根据已经存在的商品)查询建议关键词根据Asin
  BatchKeyword,
  listByKeyword,   // 表现明细查询
  getKeywordBidRecommendations,   // 根据关键词获取建议竞价
  getListExistKeywords,  // 查询已经存在关键词
  getListNegativeKeywords,   // 查询已存在的否定关键词
  addKeyword,   // 新增关键词
  addNegativeKeyword,   // 新增否定关键词
  modifyBid,  // 修改竞价
  addFocuson,  // 新增关注
  cancelFocuson,  // 取消关注
  updateNeKeyword,  // 修改修改否定关键词状态
}from "@/api/keyword.js";
import {
  listByGroupId,   // 查询已经存在商品
}from "@/api/Advertising/advertising.js";
import {
  pageInit,   // 查询初始化
  listPage,   // 查询初始化
}from '@/api/stockControl.js'
import TimeQuantum from '@/components/TimeQuantum'
import TendencyChart from '@/views/Advertising/TendencyChart'
export default {
   components: {
     TimeQuantum,
     TendencyChart   
   },
   data() {
     return {
       btnLoading: false,
       btnLoading2: false,
       buttonLoading: false,
       buttonLoading2: false,
       buttonLoading3: false,
       drawer: false,
       percentShow: false,
       rmbShow: false,
       batchAdjustBidVisible: false,
       batchApplicationVisible: false,
       batchSuspendVisible: false,
       batchStartVisible: false,    // 批量启动
       batchArchiveVisible: false,  // 批量归档
       CurSelecteOption: "third",
       CurSelecteName: 'first',
       activeNames: ['1'],
       screen: ["exact", "phrase", "broad"],
       radioAccurate: "否定精准",
       textarea: "",  // 文本域
       textField: "", // 文本域
       middle_textarea: "",  // 文本域
       labelPosition: 'right',
       direction: 'btt',
       loading: false,
       total: 0,   // 总条数
       total_count: 0,  // 总条数
       formInline: {
         selectValue: "1",
         numberValue: ""
       },
       formLabelAlign: {
         adActivity: "",
         adGrounp: ""
       },
       pageInfoList: {
         adCombination: "",  //  广告组合
         adActivity: "",   //  广告活动
         adGroup: "",  //  广告组
         adTypes: "",  //  广告类型
         keyWordState: "",   // 关键词状态
         adActiveState: "",  // 所有的活动状态
         adAttentionState: "",  // 所有的关注状态
         keyWords: "",  // 关键词
         current: 1,    // 当前页
         pageSize: 20,   // 每一页显示多少条
         shopId: "",  //  店铺id
         marketplaceId: "",    // 站点id
         startDate: "",   //  开始时间
         endDate: "",     // 结束时间
         profileId: "",
         sort:'',//排序
         sortColumn:'',
       },
       batchInfo: {
         operateType: "",
       },
       suggested: "",
       rangeStart: "",
       rangeEnd: "",
       startTime: "",
       endTime: "",
       shopLists: [],
       tableData: [],
       tdata: [],
       multipleSelection: [],
       keyWordVisible: false,
       keywordStateList: [],
       groupStateList: [],
       matchTypeList: [],
       campaignsList: [],
       portfoliosList: [],
       groupList: [],
       adCampaignList: [],   // 广告活动
       groupsList: [],
       arrList: [],
       type: 0,   // type: 1  查看  type 2 隐藏
       data: 0,   // data: 1  查看  data 2 隐藏
       radioState_flag: false,
       show_div: false,
       show_adGroup: false,
       formData: {
         status: "",   // 商品状态 
         current: 1,
         pageSize: 20,
         queryFrom: 'advert'
       },
       keyword: "",  // 请输入sku
       select_Asin: 'asin', 
       arrayDataList:[],
       searchTdata: [],
       rightData: [],   // 右边表格数据
       right_Data: [],  // 右边表格区域
       left_Tdata: [],
       goodsStatusList: [],
       subASINList: [],
       radioState: "",
       show_mainContent: false,
       negativeKeywordType: [],
       keywordType: [],
       detailsObject: {},
       add_obj: {},
       AdGroupInfoObj: {},
       operateTypeList: [],
       adjustBidOperateTypeList: [],
       commandId: "",
       keywordIdList: [],
       chartInatance: null,  //  保存chartsInstance 数据
       ListOfData: [],
       keywordIds: [],
       adGroupId: "",   // 广告组id
       adCampaignId: "",  // 广告活动id
       arrayCollect: [],
       favoriteTypeList: [],
       campaignsStateList: [],
       dialogLoading: false,
       adVertisCampaignList: [],
       adCollectionList: [],
       list: [],
       typeList: [],
       archivedNoKeywordsIds:[],  //  归档否定关键字
       dataType: "",
       legendList: [
            {name:"曝光量",prop:'impressions'},
            {name:"点击量",prop:'clicks'},
            {name:"点击率",prop:'clickRate',persent:true},
            {name:"花费",prop:'cost',currency:true},
            {name:"CPC均价",prop:'clickAveCost',currency:true},
            {name:"订单数",prop:'attributedConversions14d'},
            {name:"CVR",prop:'cvr',persent:true},
            {name:"CPA",prop:'cpa',currency:true},
            {name:"销售额",prop:'attributedSales14d'},
            {name:"ACoS",prop:'acos',persent:true},
            {name:"RoAS",prop:'roas',persent:true},
        ],
       keywordTextShow: "",
       adGroupNameShow: "",
       currency:'',
       pictLoading: false,
     }
   },
   created() {
     this.getPageInit(); 
     setTimeout(()=> {
       this.judgeCombina();
       this.judgeActivity();
     }, 1000)
   },
   mounted() {},
   watch: {
     $route(to) {
       this.judgeActivity();
       let queryInfo = this.$route.query;
       if(JSON.stringify(queryInfo)!='{}') {
         if(queryInfo.type == 'getList') {
           this.pageInfoList.profileId = Number(queryInfo.profileId);
           this.groupList.forEach(item=> {
             // 广告活动初始化
             this.pageInfoList.adActivity = item.campaignId;
             if(item.id == queryInfo.id) {
               this.pageInfoList.adGroup = item.id;
             }
           })
           this.handleSearch();
         }
       }else {
          this.handleSearch(); 
       }
     },
   },
   computed: {
     addedNoGoods() {
       let num = 0;
       this.tdata.forEach(data=> {
         if(data.operation==1) {
           num++
         }
       })
       return num;
     },
     addedNokeywords() {
      let num = 0;
      this.right_Data.forEach(item=> {
        if(item.exist == true) {
           num++
        }
      })
      return num;
     }
   },
   methods: {
     // 需要归档的 
     updateNeKeyword() {
        this.pictLoading = true;
        updateNeKeyword({type:"campaignNegativeKeywords", state:'archived',keywordIds: this.archivedNoKeywordsIds},this.pageInfoList.profileId)
         .then(res=> {
            this.pictLoading = false;
            if(res.data.code == 200) {
              this.$message.success(res.data.message);
              this.getListNegativeKeywordsList(this.adGroupId);
            }else {
              this.$message.error(res.data.message)
            }
          })
     },
     selectNoKeywords(val){
       this.archivedNoKeywordsIds=val.map(item=>{
           return item.keywordId
       })
     },
     archivedKeywordSelect(row, id) {
       if(row.operation==1){
         return true
       }else {
         return false
       }
     },
     checkGroup(parentId, subId, profileId,currency) {
       this.pageInfoList = {
         current: "",
         pageSize: "20",
         adCombination: "",
         adActivity: "",
         adGroup: "",
         adTypes: "",
         keyWordState: "",
         adActiveState: "",
         adAttentionState: "",
         keyWords: "",
         profileId: "",
         startDate:this.pageInfoList.startDate,
         endDate: this.pageInfoList.endDate,
       }
       this.currency=currency
       this.pageInfoList.profileId = profileId;
       this.pageInfoList.shopId = parentId;
       this.pageInfoList.marketplaceId = subId;
       this.getPageInit("currentPage");
     },
     getPageInit(val) {
       PageInit(this.pageInfoList.profileId||this.$store.state.profileId?this.pageInfoList.profileId||this.$store.state.profileId:0)
        .then(res=> {
          if(res.data.code == 200) {
            this.keywordStateList = res.data.data.keywordStateList;
            this.groupStateList = res.data.data.groupStateList;
            this.campaignsStateList = res.data.data.campaignsStateList;
            this.matchTypeList = res.data.data.matchTypeList;
            this.adVertisCampaignList = res.data.data.campaignsList;
            this.campaignsList = res.data.data.campaignsList;
            this.portfoliosList = res.data.data.portfoliosList;
            this.groupList = res.data.data.groupList;
            this.shopLists = res.data.data.shopList;
            this.operateTypeList = res.data.data.operateTypeList;
            this.favoriteTypeList = res.data.data.favoriteTypeList;
            this.adjustBidOperateTypeList = res.data.data.adjustBidOperateTypeList;
            if(this.pageInfoList.profileId == "") {
              this.shopLists.forEach(item=> {
                item.marketPlaceTree.forEach(data=> {
                  if(data.isSelected === 1) {
                    this.pageInfoList.profileId = data.profileId;
                    this.pageInfoList.current = 1;
                    this.currency=data.currency
                  }
                })
              })
            }
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
          if(val=='currentPage'){
            this.handleSearch();
            return;
          }
        })
        .then(()=> {
          this.judgeActivity();
          let getInfo = this.$route.query
          if(JSON.stringify(getInfo)!='{}') {
            if(getInfo.type == 'getList') {
              this.pageInfoList.profileId = Number(getInfo.profileId);
              this.pageInfoList.adActivity = Number(getInfo.campaignId);
              this.pageInfoList.adGroup = Number(getInfo.id);
              this.handleSearch();  
            }
          }else {
            this.handleSearch();
          }
        })
     },
     // 查询事件
     handleSearch: function() {
       this.$store.commit('setProfileId', this.pageInfoList.profileId);
       this.loading = true;
       let params = {
         current: this.pageInfoList.current,  //  当前第几页
         pageSize: this.pageInfoList.pageSize,  //  每页显示多少条
         shopId: this.pageInfoList.shopId,
         marketplaceId: this.pageInfoList.marketplaceId,
         startDate: this.pageInfoList.startDate,
         endDate:  this.pageInfoList.endDate,
         portfolioId: this.pageInfoList.adCombination,    // 直接选择组合
         campaignId: this.pageInfoList.adActivity,     // 直接选择广告活动
         groupsId: this.pageInfoList.adGroup,       //  直接选择组
         matchType: this.pageInfoList.adTypes,    //  匹配类型
         keywordState:  this.pageInfoList.keyWordState,  // 关键词状态 
         campaignStatus: this.pageInfoList.adActiveState,   //  广告活动状态
         isFavorite: this.pageInfoList.adAttentionState,   // 关注状态
         keyword: this.pageInfoList.keyWords,
         profileId: this.pageInfoList.profileId,
         sort: this.pageInfoList.sort,
         sortColumn: this.pageInfoList.sortColumn,
       }
       getListPage(params)
        .then(res=> {
          this.tableData = [];
          if(res.status == 200) {
            this.tableData = res.data.values;
            this.total = res.data.pageInfo.total;
            this.loading = false;  
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
     },
     //  弹框初始化
     getInitInfor() {
       pageInit()
       .then(res=> {
         if(res.data.code == 200) {
           this.subASINList = res.data.data.searchList;
           this.goodsStatusList = res.data.data.productStatusList;
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       })
     },
     // 管理关键词
     handleManage: function() {
       this.formLabelAlign.adActivity = "";
       this.formLabelAlign.adGrounp = "";
       this.formData.status = "";
       this.keyword = "";
       this.show_div = false;
       this.show_adGroup = false;
       this.show_mainContent = false;
       this.searchTdata = [];
       getAddInit(this.pageInfoList.profileId)
        .then(res=> {
          if(res.data.code == 200) {
            this.adCampaignList = res.data.data.campaignsList;
            this.groupsList = res.data.data.groupsList;
            this.negativeKeywordType = res.data.data.negativeKeywordType;
            this.keywordType = res.data.data.keywordType;
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
       this.getInitInfor();
       this.keyWordVisible = true;
     },
     // 广告活动切换
     changeValue(val) {
       this.adCampaignId = val;
       this.adCampaignList.forEach((eq, index)=> {
         if(val == eq.id) {
           this.detailsObject = this.adCampaignList[index];
         }
       })
       this.formLabelAlign.adGrounp = "";
       this.type = 1;
     },
     // 根据广告组查询否定关键词
     getListNegativeKeywordsList(val) {
       let params2 = {
         groupId: val
       }
       getListNegativeKeywords(params2)
       .then(res=> {
         if(res.data.code == 200) {
           var result = [];
           res.data.data.forEach(data=> {
             let object = {
               adGroupId: data.adGroupId,
               campaignId: data.campaignId,
               keywordId: data.keywordId,
               keywordText: data.keywordText,
               matchType: data.matchType,
               state: data.state,
               operation: 1,
             }
             result.push(object)
           })
           // res.data.data.map((item, index)=> {
           //   result.push(Object.assign({}, item, {existing: 'true'}))
           // })
           this.tdata = result;
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       })
     },
     // 广告组切换事件 
     handleChange(val) {
       this.asyncSearch();    // 立即查询
       this.getListNegativeKeywordsList(val);
       this.adGroupId = val;
       this.groupsList.forEach(it=> {
         if(val == it.id) {
           var AdGroupInfo = {
             default: it.default,
             targetType: it.targetType
           }
           this.AdGroupInfoObj = AdGroupInfo;
         }  
       })
       this.data = 1;
       // 根据已存在的广告商品查询已经存在的关键词
       let params = {
         groupId: val
       }
       getListExistKeywords(params)
        .then(res=> {
          this.right_Data = [];
          if(res.data.code == 200) {
            this.right_Data = res.data.data;
            console.log(this.right_Data);
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
        if(this.dataType == "手") {
          this.show_mainContent = true;
        }else if(this.dataType == "自") {
          this.show_mainContent = false;
        };
        // 查询已经存在的广告商品
        listByGroupId(val, this.pageInfoList.profileId)
        .then(res=> {
          if(res.data.code == 200) {
            this.rightData = [];
            res.data.data.forEach(data=> {
              let object = {
                exist: 1,
                imageUrl: data.imageUrl,
                marketPlaceImageUrl: data.marketPlaceImageUrl,
                commoditySku: data.commoditySku,
                sellableQuantity: data.sellableQuantity,
                canSellDay: data.canSellDay,
                sellPrice: data.sellPrice,
                asin: data.asin,
              }
              this.rightData.push(object);
              console.log(this.rightData);
            })
          }
        }), 
        this.$nextTick(()=> {
          setTimeout(()=> {
            this.getExistKeywordsByAsin();  // 根据已存在商品的asin 来查询已存在的关键词
          }, 500)
        })
     },
     // 根据已存在商品的asin 来查询已存在的关键词
     getExistKeywordsByAsin() {
      var ids = [];
      this.rightData.forEach(data=> {
        ids.push(data.asin)
      })
      if(ids.length == 0) {
        return false;
      }
      if(ids.length > 0){
        getSuggestedKeywordsByAsin(ids, this.pageInfoList.profileId)
        .then(res=> {
          console.log(res, 'opopoopopopop');
          if(res.data.code == 200) {
            this.left_Tdata = res.data.data;
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }  
        })
      }
     },
     clickBtn(type) {
       if(type == 1) {
         this.type = 2;
         this.show_div = true;
       }else if(type == 2) {
         this.type = 1;
         this.show_div = false;
       }
     },
     handleClick(data) {
       if(data == 1) {
         this.data = 2;
         this.show_adGroup = true;
       }else if(data == 2) {
         this.data = 1;
         this.show_adGroup = false;
       }
     },
     // 立即查询(查询所有的商品)
     asyncSearch() {
       this.buttonLoading = true;
       let sku = '';
       if(this.select_Asin == '' || this.keyword == '') {
          sku = null;
       }else if(this.select_Asin == 'asin') {
          sku = { asin: this.keyword };
       }else if(this.select_Asin == 'parent_asin') {
          sku={ parent_asin: this.keyword }
       }else if(this.select_Asin == 'commodity_sku') {
          sku={ commodity_sku: this.keyword }
       }
       this.dialogLoading = true;
       listPage({...sku,...this.formData, shopId: this.pageInfoList.shopId, marketplaceId: this.pageInfoList.marketplaceId})
       .then(res=> {
         this.dialogLoading = false;
         this.buttonLoading = false;
         this.searchTdata = [];
         if(res.status == 200) {
           const arr = res.data.values.map(item=> {
             item.operation = 0;
             return item
           })
           // 判断右侧是否有数据
           if(this.rightData.length!=0) {
             const ids = this.rightData.map(item=> {
               return item.id
             })
             arr.forEach(item=> {
               ids.forEach(data=> {
                 if(data == item.id) {
                   item.operation=1
                 }
               })
             })
           }
           this.searchTdata = arr;
           console.log(this.searchTdata, 'pppppppppppppppp');
           this.total_count = res.data.pageInfo.total;
         }else {
           this.$message.error(res.data.message);
         }
       })
     },
     // 调整竟价
     onClickBid(row, index) {
       this.$prompt('竞价', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         inputValue: row.bid,
         inputPattern: /^[0-9]+([.]{1}[0-9]+){0,1}$/,
         inputErrorMessage: '只能输入纯数字'
       }).then(({ value })=> {
         this.loading = true;
         modifyBid({bid: value, ids: [row.keywordId], type: 'keywords'}, this.pageInfoList.profileId)
         .then(res=> {
           this.loading = false;
           if(res.data.code == 200) {
             this.$message.success(res.data.message);
             this.tableData[index].bid = value;
           }else {
             this.$message.error(res.data.message);
           }
         })
       })
     },
     // 建议竞价 
     onObtainBtn(index, keywordId) {
       this.loading = true;
       getKeywordBidRecommendations(keywordId, this.pageInfoList.profileId)
        .then(res=> {
          this.loading = false;
          if(res.data.code == 200) {
            if(res.data.data == "") {
              this.$message.warning("暂无建议竞价")
            }
            this.$set(this.tableData[index], 'suggested', res.data.data.suggested);
            this.$set(this.tableData[index], 'rangeEnd',res.data.data.rangeEnd);
            this.$set(this.tableData[index], 'rangeStart',res.data.data.rangeStart);
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
       }) 
     }, 
     handleSizeChange(newSize) {
       this.pageInfoList.pageSize = newSize;
       this.handleSearch();
     },
     handleCurrentChange(newCurrent) {
       this.pageInfoList.current = newCurrent;
       this.handleSearch();
     }, 
     handleSizeChange2(newSize) {
       this.formData.pageSize = newSize;
       this.asyncSearch();
     },
     handleCurrentChange2(newCurrent) {
       this.formData.current = newCurrent;
       this.asyncSearch();
     },
     topTime(val) {
       this.pageInfoList.startDate = val.value[0];
       this.pageInfoList.endDate = val.value[1];
     },
     // table 表格多选状态 
     handleSelectionChange: function(val) {
       this.multipleSelection = val;
       var keywordIds = [];
       this.multipleSelection.forEach(data=> {
          keywordIds.push(data.keywordId)
       })
       this.keywordIdList = keywordIds;
     },
     handleCommand(command) {
       this.commandId = command;
       if(this.multipleSelection.length == 0) {
         this.$message.error('请先勾选需要批量操作对象');
         return false;
       }
       if(command == 1) {     // 调整竟价
         this.rmbShow = true;
         this.batchAdjustBidVisible = true;
       }else if(command == 2) {   // 应用建议竞价
         this.batchApplicationVisible = true;
       }else if(command == 3) {   // 置为暂停
         this.batchSuspendVisible = true;
       }else if(command == 4) {   // 恢复启动
         this.batchStartVisible = true;
       }else if(command == 5) {   // 置为归档
         this.batchArchiveVisible = true; 
       }else if(command == 6) {   // 同步状态/竞价
         let data = {
           keywordIds: this.keywordIdList,
           operateType: this.commandId,
         }
         BatchKeyword(data, this.pageInfoList.profileId)
          .then(res=> {
           if(res.data.code == 200) {
             this.$message.success(res.data.data);
             this.handleSearch();
           }else if(res.data.code == 500) {
             this.$message.error(res.data.message);
           }
         })
       }
     },
     // 添加全部  
     GoodsAll(info) {
       if(info == 'del') {
         this.rightData.forEach((item, index)=> {
           item.operation = 0;
           if(!item.exist) {
             this.rightData.splice(index)
           }
         })
         this.asyncSearch();
       }else {
         const arr = this.searchTdata.filter(item=> {
            return item.operation == 0
         })
         arr.forEach(item=> {
           item.operation = 1
         })
         this.rightData = [...this.rightData, ...arr]
       }
     },
     // 添加全部  
     handleAllAdd() {
       const array = [];
       this.left_Tdata.forEach(data=> {
         this.screen.forEach(item=> {
           array.push({ bid: "", keywordText:data, matchType:item })
         })
       })
       if(this.right_Data.length == 0) {
         this.right_Data = array;
       }else {
         this.right_Data.forEach((j, index)=> {
           array.forEach((i, _ind)=> {
             if(i.keywordText == j.keywordText && i.matchType == j.matchType) {
               array.splice(_ind,1)
             }
           })
         })
         this.right_Data = [...this.right_Data, ...array];
       }
       this.left_Tdata = [];
     },
     // 添加关键词
     addKeywords(item) {
       const arr = [];
       this.screen.forEach(i=> {
         arr.push({ bid: "", keywordText:item, matchType:i })
       })
       if(this.right_Data.length == 0) {
         this.right_Data = arr;
       }else {
         this.right_Data.forEach((j, index)=> {
           arr.forEach((i, _ind)=> {
             if(i.keywordText == j.keywordText && i.matchType == j.matchType) {
               arr.splice(_ind,1)
             }
           })
         })
         this.right_Data = [...this.right_Data, ...arr]
       }
     },
     // 删除关键词
     delKeywords(index, rows) {
       rows.splice(index, 1);
     }, 
     //  添加关键词
     async_Add() {
      if(!this.middle_textarea) {
        this.$message.error('请输入内容');
        return false;
      }
      this.arrayCollect = [];
      this.screen.forEach(data=> {
        // bid: 竞价;   keywordText: 关键词  matchType： 匹配类型
        this.arrayCollect.push({bid: "", keywordText: this.middle_textarea, matchType:data})
      })
      this.right_Data = [...this.right_Data, ...this.arrayCollect];
      this.middle_textarea = "";
     },
     // 添加关键词  
     addKeyWord() {
       if(!this.textarea) {
         this.$message.error('请输入内容')
         return false;
       }
       this.arrayDataList = [];
       let obj = {
         keywordText: this.textarea,
         matchType: this.radioAccurate == '否定精准'? 'negativeExact': 'negativePhrase',
       }
       this.arrayDataList.push(obj);   // 新增基础上添加
       this.tdata= [...this.tdata, ...this.arrayDataList];   // 原有基础上添加
       this.textarea = "";
     },
     // 单个删除  
     handleDelete(_index) {
       this.tdata.splice(_index, 1)
     },
     // 全部删除
     handleAllDelete2() { 
       if(this.tdata.length==0) {
         this.$message.warning("只能删除新增的，已存在的可点击归档以达到删除的目的");
       }else {
         // this.tdata=[];
         this.tdata.forEach((item,index)=>{
           if(item.operation!=1){
             this.tdata.splice(index)
           }
         })
       }
     },
     // 确定新增关键词
     asyncAddKeyword() {
       this.buttonLoading2 = true;
       const arr = [];
       this.arrayCollect.forEach(item=> {
         let object = {
           bid: item.bid,
           keywordText: item.keywordText,
           matchType: item.matchType
         }
         arr.push(object)
       })
       let data = {
         adGroupId: this.adGroupId,
         amzAdKeywordForGroupAddReqList: arr,
         campaignId: this.adCampaignId,
         profileId: this.pageInfoList.profileId
       }
       addKeyword(data, this.pageInfoList.profileId)
        .then(res=> {
          this.buttonLoading2 = false;
          if(res.data.code == 200) {
            this.$message.success('添加成功');
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
     },
     //  确定新增否定关键词
     asyncAddNoKeyword() {
       this.buttonLoading3 = true;
       const arrList = [];
       this.arrayDataList.forEach(data=> {
         let obj = {
           keywordText: data.keywordText,
           matchType: data.matchType
         }
         arrList.push(obj);
       })
       let data = {
         adGroupId: this.adGroupId,
         amzAdKeywordForGroupAddReqList: arrList,
         campaignId: this.adCampaignId,
         profileId: this.pageInfoList.profileId
       }
       addNegativeKeyword(data, this.pageInfoList.profileId)
        .then(res=> {
          this.buttonLoading3 = false;
          this.asyncAddNoKeyword = false;
          if(res.data.code == 200) {
            this.getListNegativeKeywordsList(this.adGroupId);  // 查询数据
            this.$message.success(res.data.message);
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
     },
     // 单个向右侧添加事件  
     toAdded(item) {
       // operation: 1 表示新增
       // 右边新数组
       var rightNewData = [];
       rightNewData.push({...item, operation: 1});    // 合并数组
       this.rightData = [...this.rightData, ...rightNewData]
       this.asyncSearch();
       const arr = [];
       this.rightData.forEach(i=> {
         arr.push(i.asin)
       })
       getSuggestedKeywordsByAsin(arr, this.pageInfoList.profileId)
       .then(res=> {
         this.left_Tdata = [];
         if(res.data.code == 200) {
           this.left_Tdata = res.data.data;
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       })
     },
     // 单个向左侧删除个数
     toGoods(item, _index) {
       item.operation = 0;
       this.rightData.splice(_index, 1);
       this.asyncSearch();
     },
     // tabs 栏切换事件
     handle_Click_first(tab) {
       if(tab.name == "second") {
         this.radioState = "asin";
         this.radioState_flag = true;
       }else if(tab.name == "first") {
         this.radioState_flag = false;
       }
     },
     // 添加
     handle_Add() {
       let data = {
         queryType: this.radioState,
         asins: this.textField.replace(/\n/g,",").split( ',' )
       }
       getAsinByAsins(data)
       .then(res=> {
         if(res.data.code == 200) {
           res.data.data.forEach(item => {
            this.rightData.unshift(item); 
            this.textField = "";
           })
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       })
     },
     // 删除全部
     handleAllDelete1() {
      this.right_Data.forEach((data,index)=> {
        if(data.exist != true) {
          this.right_Data.splice(index)
        }
      })
     },
     // 收藏与取消
     focusChange(id, value) {
       this.loading = true;
       let params = {
         keywordId: id
       }
       if(value == 0) {
         addFocuson(params)
           .then(res=> {
             this.loading = false;
             if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.handleSearch();
             }else {
               this.$message.error(res.data.message);
             }
          })
       }else if(value == 1) {
         cancelFocuson(params)
          .then(res=> { 
            this.loading = false;
            if(res.data.code == 200) {
              this.$message.success(res.data.message);
              this.handleSearch();
            }else {
              this.$message.error(res.data.message);
            }
         })
       }
     },
     changeOperateType(val) {
       this.formInline.selectValue = val;
       if(val == 2 || val == 3) {
         this.percentShow = true;
         this.rmbShow = false;
       }else {
         this.percentShow = false;
         this.rmbShow = true;
       }
     }, 
     // 调整竞价确定
     asyncSubmit() {
       this.btnLoading = true;
       if(this.commandId != 1) {
         if(this.commandId == 2) {
           let data = {
             keywordIds: this.keywordIdList,
             operateType: this.commandId,
           }
           BatchKeyword(data, this.pageInfoList.profileId)
            .then(res=> {
            this.btnLoading = false;
            if(res.data.code == 200) {
              this.$message.success(res.data.message);
              this.batchApplicationVisible = false,
              this.handleSearch();
            }else if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }
           })
         }else if(this.commandId == 3) {
           let data = {
             keywordIds: this.keywordIdList,
             operateType: this.commandId,
           }
           BatchKeyword(data, this.pageInfoList.profileId)
            .then(res=> {
              this.btnLoading = false;
             if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.batchSuspendVisible = false,
               this.handleSearch();
             }else if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }
           })
         }else if(this.commandId == 4) {
           let data = {
             keywordIds: this.keywordIdList,
             operateType: this.commandId,
           }
           BatchKeyword(data, this.pageInfoList.profileId)
            .then(res=> {
             this.btnLoading = false;
             if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.batchStartVisible = false,
               this.handleSearch();
             }else if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }
           })
         }else if(this.commandId == 5) {
           let data = {
             keywordIds: this.keywordIdList,
             operateType: this.commandId,
           }
           BatchKeyword(data, this.pageInfoList.profileId)
            .then(res=> {
             this.btnLoading = false;
             if(res.data.code == 200) {
               this.$message.success(res.data.message);
               this.batchArchiveVisible = false,
               this.handleSearch();
             }else if(res.data.code == 500) {
               this.$message.error(res.data.message);
             }
           })
         }
       }else {
         if(this.formInline.selectValue == 2 || this.formInline.selectValue == 3) {
           let data = {
             defaultBidUpdateReq: {
               operateType: this.formInline.selectValue,
               percent: this.formInline.numberValue,
             },
             keywordIds: this.keywordIdList,
             operateType: this.commandId
           }
           BatchKeyword(data, this.pageInfoList.profileId)
            .then(res=> {
              this.btnLoading = false;
              if(res.data.code == 200) {
                this.$message.success(res.data.message);
                this.batchAdjustBidVisible = false;
                this.handleSearch();
              }else if(res.data.code == 500){
                this.$message.error(res.data.message);
              }
           })
         }else {
           let data = {
             defaultBidUpdateReq: {
               operateType: this.formInline.selectValue,
               amount: this.formInline.numberValue,
             },
             keywordIds: this.keywordIdList,
             operateType: this.commandId
           }
           BatchKeyword(data, this.pageInfoList.profileId)
            .then(res=> {
              this.btnLoading = false;
              if(res.data.code == 200) {
                this.$message.success(res.data.message);
                this.batchAdjustBidVisible = false;
                this.handleSearch();
              }else if(res.data.code == 500) {
                this.$message.error(res.data.message);
              }
           })
         }
       }
     },  
     // 批量应用建议确定 
     asyncConfirm() { },
     changeTime(val) {
       this.startTime = val.value[0];
       this.endTime = val.value[1];
     },
     changeStatus(currendId, status, index) {
       // 改变状态, 调用批量关键词操作
       this.loading = true;
       this.keywordIds = [currendId];
       if(status == 'enabled') {
         this.batchInfo.operateType = 4
       }else if(status == 'paused') {
         this.batchInfo.operateType = 3
       }
       let data = {
         keywordIds: this.keywordIds,
         operateType: this.batchInfo.operateType
       } 
       BatchKeyword(data, this.pageInfoList.profileId)
        .then(res=> {
          this.loading = false;
          if(res.data.code == 200) {
            this.$message.success(res.data.message);
            this.tableData[index].state = status;
            // this.handleSearch();
          }else if(res.data.code == 500) {
            this.$message.error(res.data.message);
          }
        })
     },
     getChart(row){
       this.keywordTextShow = row.keywordText;
       this.adGroupNameShow = row.adGroupName;
       this.$store.commit('setAdvName',row.keywordId)
       this.analysisClick()
     },
     // 分析历史趋势 
     analysisClick(val) {
       let params = {
        profileId: this.pageInfoList.profileId,
        startDate: val?val.value[0]:'',
        endDate: val?val.value[1]:'',
        keywordId: this.$store.state.advName
       }
       listByKeyword(params)
       .then(res=> {
         if(res.data.code == 200) {
           this.ListOfData = res.data.data;
         }else if(res.data.code == 500) {
           this.$message.error(res.data.message);
         }
       })
       this.drawer = true
     },
     // 根据组合选择广告活动
     change_event(id) {
       this.adVertisCampaignList.forEach(item=> {
         if(item.portfolioId == id) {
           this.list.push(item);
         }
       })
       this.adVertisCampaignList = this.list;
       this.pageInfoList.adActivity = "";
     },
     // 判断组合id 是否为空
     judgeCombina() {
       if(!this.pageInfoList.adCombination) {
         this.adVertisCampaignList = this.campaignsList;
       }else {
         this.adVertisCampaignList = this.list;
       }
     },
     // 判断活动id 是否存在
     judgeActivity() {
       if(!this.pageInfoList.adActivity) {
         this.adCollectionList = this.groupList;
       }else {
         this.adCollectionList = this.typeList;
       }
     },
     // 根据广告活动筛选选择组 
     handle_event(id) {
       this.groupList.forEach(item=> {
         if(id == item.campaignId) {
           this.typeList.push(item);
         }
       })
       this.adCollectionList = this.typeList;
       this.pageInfoList.adGroup = "";
     },
     handle_select(data) {
       this.dataType = data.type;
       this.arrList = this.groupsList.filter(item=> {
         return item.campaignId == data.id;
       })
       if(data.type == "自") {
         this.formLabelAlign.adGrounp = "";
         this.show_mainContent = false;
       }
     },
     removeEvent() {
       this.pageInfoList.adActivity = "";
       this.adVertisCampaignList = this.campaignsList;
     },
     clearEmpty() {
       this.pageInfoList.adGroup = "";
       this.adCollectionList = this.groupList;
     },
     find(){
       this.pageInfoList.sort=''
       this.pageInfoList.sortColumn=''
       this.pageInfoList.current=1
       this.handleSearch()
     },
     sortTable(column){
       if(column.order=='ascending'){
           this.pageInfoList.sort='asc'
       }else if(column.order=='descending'){
           this.pageInfoList.sort='desc'
       }else if(column.order==null){
           this.pageInfoList.sort=''
           this.pageInfoList.sortColumn=''
           this.handleSearch()
           return
       }
       this.pageInfoList.sortColumn=column.prop
       this.handleSearch()
     },
     clearAsin() {
       this.keyword = "";
     },
   }
}
</script>

<style lang="scss" scoped>
  .storage {
    .append {
      display: flex;
      justify-content: flex-start;
      .seach {
        width: 100%;
        .el-select {
          margin-right: 20px;
          margin-bottom: 20px;
          width: 224px;
        }
        .el-input {
          width: 224px;
          margin-right: 20px;
        }
        .el-table {
          margin-top: 30px;
        }
        .el-pagination {
          padding-top: 40px;
          text-align: center;
          margin-bottom: 10px;
        }
      }
    }
  }
  .box-card {
    width: 600px;
  }
  .el_collapse_div {
    display: flex;
    justify-content: flex-start;
  }
  .activityInfor {
    margin-left: 10px;
    color: #999;
    font-size: 15px;
    cursor: pointer;
  }
  .showClass {
    width: 900px;
    height: 200px;
    margin-left: 60px;
    margin-bottom: 15px;
  }
  .look {
    width: 100%;
    height: 100%;
    padding-bottom: 5%;
  }
  .fn {
    width: 40%;
    height: 40px;
    float: left;
    margin-right: 5px;
    margin-bottom: 5px;
    margin-left: 2%;
    text-align: center;
  }
  .lable {
    width: 30%;
    height: 38px;
    float: left;
    line-height: 40px;
    border: 1px solid #e5e5e5;
    border-radius: 4px 0px 0px 4px;
  }
  .value {
    width: 65%;
    height: 40px;
    float: left;
    line-height: 40px;
    background-color: #fff;
    border: 1px solid #ccc;
    text-align: left;
    padding-left: 50px;
    box-sizing: border-box;
    border: 1px solid #e5e5e5;
    border-left: none;
    color: #666666ff;
    border-radius: 0px 4px 4px 0px;
  }
  .class_div {
    width: 600px;
    height: 60px;
    margin-left: 70px;
    margin-bottom: 15px;
  }
  .nav_top {
    margin-left: 15%;
    margin-bottom: 20px;
  }
  .el_nav_middle_div {
    display: flex;
    justify-content: flex-start;
  }
  .el_nav_bottom_div {
    display: flex;
    justify-content: flex-start;
  }
  .el_row_top {
    display: flex;
    justify-content: flex-start;
    margin-top: 8px;
  }
  .el_filter {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
  }
  .newkeywords {
    display: flex;
    justify-content: flex-end;
    margin-top: 18px;
    margin-bottom: 18px;
  }
  .el-async_keyword {
    display: flex;
    justify-content: flex-end;
  }
  .pic{
    width:160px;
    height: 160px;
    font-size:8px;
    color: #909399;
    text-align: center;
    line-height: 160px;
    margin-right: 10px;
    span{
      display: block;
      background: #EBEEF5;
      width: 100%;
      height: 100%;
    }
  }
  .noPic{
    width: 60px;
    height: 60px;
    text-align: center;
    line-height: 60px;
    font-size:8px;
    color: #909399;
    margin-right: 15px;
    span{
      display: block;
      background: #EBEEF5;
      width: 100%;
      height: 100%;
    }
  }
  .suspend{
    p{
      span{
        display: inline-block;
        width: 180px;
        margin-right: 10px;
        font-size: 13px;
      }
    }
  }
  .infoText{
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 25px;
    margin: 15px 0 20px 0;
  }
  .goodsInfo{
    display: flex;
    >div:nth-of-type(2){
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
    }
    p{
      font-size: 12px;
      color: #909399;
    }
  }
  .botton_group_bottom {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
  }
  .keywordClass {
    margin-top: 20px;
    left: 100px;
    margin-bottom: 30px;
    width: 2650px;
    height: 650px;
    border-radius: 20px;
  }
.item {
    height: 85px;
    border-bottom:1px solid #F0F0F0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 10px;
    .pic{
        width: 70px;
        height: 70px;
        margin-right:10px
    }
    .SKU{
        height: 50px;
        width: 170px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .state{
        display: inline-block;
        width: 24px;
        height: 16px;
        vertical-align: middle;
        margin-top: -4px;
    }
    .realizable{
        width: 120px;
        height: 50px;
        line-height: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
    }
    .price{
        width: 150px;
        height: 60px;
        p{
          line-height: 60px;
        }
    }
}
.handleAll {
  position: relative;
  z-index: 11;
  float: right;
}
.removeAll {
  position: relative;
  z-index: 11;
  float: right;
}
.el-icon-star-off{
  color:grey;
}
.el-icon-star-on{
  color: #F7BA2A;
  font-size: 20px !important;
}
</style>